export default {
  "logistics": {
    "logistics_delivery_way": "Shipping Delivery Type",
    "pay_sum": "Payment amount",
    "city": "City",
    "pay": "Pay",
    "transport_plan": "Shipping plan",
    "transport_plan_name": "Shipping plan name",
    "transport_box_number": "Box quantity of shipping",
    "please_input_plan_name_or_no": "Please enter plan name or number",
    "account_balance": "Account balance",
    "not_sufficient_funds": "Balance insufficient",
    "immediately_top_up": "Recharge now",
    "account_balance_pay": "Pay by account balance",
    "please_select_payment_method": "Please select payment method",
    "service_charge": "Handling fee",
    "nonsupport_logistics": "Logistics is unsupported currently.",
    "customs_clearance_information_incomplete": "Customs clearance info incompleted.",
    "chargeable_weight": "Chargeable weight",
    "chargeable_volume": "Chargeable volume",
    "volume_weight_coefficient": "Volume weight ratio: divide",
    "create_transport_plan": "Create shipping plan",
    "set_transport_info": "Set shipping info",
    "confirm_transport_plan": "Confirm shipping plan",
    "transportation_plan_number": "Shipping plan number",
    "transportation_plan_name": "Shipping plan name",
    "destination_type": "Destination type",
    "fba_warehouse_code": "FBA warehouse code",
    "warehouse_code": "Warehouse code",
    "shipping_address": "Delivery address",
    "receiving_address": "Delivery address",
    "originate": "Shipping from",
    "plan_name_not_null": "Plan name can not be empty",
    "transport_route": "Shipping route",
    "shipments_address": "Dispatching address",
    "shipments_warehouse": "Dispatching warehouse",
    "logistics_center_harvest_address": "Logistics center receiving address",
    "contacts": "Contact person",
    "shipments_contacts": "Consigner",
    "recipients": "Consignee",
    "return_contact_name": "退货联系人",
    "business_packaging_size": "Product packaging dimension",
    "before_please_select_inventory_warehouse": "Please select warehouse for inventory first.",
    "size": "Dimension",
    "logistics_select_hint": "System will offer the best logistics center according to your dispatching address.",
    "max_capacity": "Maximum capacity",
    "weight_packing_material": "Weight of packaging material",
    "cost": "Cost",
    "weight": "Weight",
    "se_logistics": "SE Logistics",
    "pack_material": "包装材料",
    "product": "产品",
    "currency_inconsistency": "Currency of declared value in customs clearance info must be consistent.",
    "summary_customs_clearance_information": "Summary of customs clearance info",
    "save_and_order": "Save & place order",
    "count": "Amount",
    "contact_number": "Contact phone number",
    "send_out_contact_number": "发货人联系电话",
    "receiving_contact_number": "收件人联系电话",
    "usable_inventory": "Available inventory",
    "fba_transport_no_placeholder": "Contain 12 alphanumeric digits, such as:FBA12ABC123456",
    "product_track_no_placeholder": "Contain 8 alphanumeric digits, such as:2AB3CDEF",
    "trade_name": "Product name",
    "apply_middle_rate": "Average declared value",
    "number": "Quantity",
    "subtotal": "Subtotal",
    "logistics_channel": "Logistics channel",
    "logistics_scheme": "Logitstics solution",
    "changing_the_data_will_affect_the_truck_quotation_of_logistics_please_re_select_logistics": "改变数据将影响物流的卡车报价,请重新选择物流",
    "please_select_logistics_scheme": "Please select logistics solution",
    "delivery_way": "Delivery Type",
    "logistics_type": "Logistics type",
    "freight_forecast_no_audit": "Freight(estimated)",
    "create_date": "Creating date",
    "selected_service": "Selected service",
    "clearance_way": "Customs clearance method",
    "clearance_file_not_upload_accomplish": "Customs clearance documents uploaded incompleted.",
    "ordinary_trade_clearance": "General trading customs clearance",
    "freight": "Shipping fee",
    "support_pdf_format": "PDF format supported",
    "customs_declaration": "Cusotms declaration paper",
    "remote_site": "Delivery Area",
    "business_site": "Commercial area",
    "residence": "Residential area",
    "contract": "Contract",
    "invoice": "Invoice",
    "packing_documents": "Packing List",
    "address_supports_door_to_door_service": "Pick Up on door service supported for this address.",
    "transport_plan_details": "Shipping plan details",
    "door_took": "Pick Up on door",
    "estimated_time_of_arrival": "Estimated arrival time",
    "transport_info": "Shipping info",
    "warehouse_address": "Warehouse address",
    "created_trans_plan": "Create shipping plan",
    "consignment_create_order": "Create distribution order",
    "select_logistics": "Select logistics",
    "select_logistics_plan": "Select logistics solution",
    "local_transport": "Domestic shipping",
    "have_paid": "Paid",
    "consignment_warehouse_no": "Distributioin warehouse code",
    "consignment": "Distributioin warehouse",
    "source_of_sales": "Sales source",
    "distribution_channels": "销售渠道",
    "consignment_order_no": "Distribution order number",
    "relation_order_no": "Related order number",
    "transport_scheme": "Shipping solution",
    "logistics_track_no": "Tracking number",
    "start_country_city": "Origin country/city",
    "destination_country_city": "Destination country/city",
    "city_search": "City(search please)",
    "enter_boxNo_PlanName_number": "Please enter carton number or inbound plan name/code.",
    "dispose_amount": "Disposal fee",
    "transport_charge": "Shipping fee",
    "transport_count": "Shipping quantity",
    "transport_box_count": "Shipping carton quantity",
    "destination_address": "Destination address",
    "relevance_out_no": "Related outbound code",
    "relevance_in_no": "Related inbound code",
    "subsequent_steps": "Following up steps",
    "placeholder_order_recipients": "Waybill number/Related waybill number/Consignee",
    "look": "Look up",
    "time": "Time",
    "status": "Status",
    "add_goods": "Add product",
    "please_select_consignment_warehouse": "Please select distribution warehouse.",
    "consignment_warehouse": "Distribution warehouse",
    "order_info": "Order info",
    "new": "New",
    "new_source_of_sales": "New sales source",
    "please_input_source_of_sales": "Please enter sales source",
    "consignment_warehouse_no_gooods": "Product not found in our distribution warehouse, please add product in distribution warehouse first.",
    "cannot_create_order": "Can not create distribution order.",
    "facility_extent": "Overdraft Limit",
    "associated_order_number": "Related Order Number",
    "shipping_plan": "Shipping Plan",
    "support_repository": "Allocation to local Storage Warehouse supported",
    "support_distribution_warehouse": "Allocation to local Distribution Warehouse supported",
    "area_code": "Area Code",
    "add_driver_contact_information": "Add driver's contact info",
    "contact_information": "Contact Info",
    "four_in_one_file": "4 in 1 document",
    "four_in_one_file_tooltip": "Customs Clearance Paper,Contract,Invoice,Packing List",
    "separate_file": "Separated document",
    "not_currently_available_logistics_scheme": "Non available logistics plan currently",
    "estimated_freight_tips": "No any surcharges included in estimated freight.",
    "place_an_order_and_get_a_transfer": "Place order and get trcking number.",
    "please_select_channel": "Please select channel.",
    "waybill_number": "Tracking Number",
    "packaging_figure": "Package picture",
    "receiving_site": "Receiving Branches",
    "canceled": "Cancelled",
    "select_quote": "选择报价",
    "carrier": "承运商",
    "price": "价格",
    "aging": "时效",
    "logistics": "物流",
    "truck": "卡车",
    "quote": "报价",
    "quote_options": "报价选项",
    "quote_details": "报价详情",
    "quote_options_tips": "卡车价格波动较大，需实时询价",
    "quote_type": "报价类型",
    "service_level": "服务等级",
    "additional_options": "附加选项",
    "inquiry_immediately": "立即询价"
  }
}